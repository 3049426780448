import { AxiosResponse } from 'axios';
import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import fetcher from 'utils/helpers/browser/fetcher';
import {
    CreateUserApiInterface,
    CreateUserFromPersonApiInterface,
    ExportInterface,
    ForgottenPasswordConfirmRequestInterface,
    ForgottenPasswordRequestInterface,
    InternationalCertificateExportData,
    MemberRegistrationApiInterface,
    ResendPasswordRequestDataInterface,
    UploadPhotoApiInterface,
} from 'submodules/api_middleware';
import { getDiscipline } from 'utils/helpers/browser/discipline';
import newFetcher, {
    fetcherFull,
    MethodEnum,
} from 'utils/helpers/browser/newFetcher';
import {
    UserOrganisationUnit,
    UserOrganisationUnitRole,
} from 'components/interfaces/MembershipPaymentInterface';
import { QueryRequestInterface } from 'submodules/api_middleware/src/interfaces/InternalInterfaces';

export type UserMe = {
    user_id: number;
    person_id: number;
    is_member: boolean;
    name: string;
    first_name: string;
    is1_logged_in: boolean;
    is1_auth_token: string | null;
    last_name: string;
    middle_name: string | null;
    member_internal_id: string | null;
    /**
     * Persons that belong under this account. Typically used for a parent and
     * the descendant minor players. The user can log in as any person from the list.
     * In most cases, there is only one person, i.e. one user = one person.
     */
    user_persons: {
        person_id: number;
        name: string;
        registered_as_minor: boolean;
        date_of_birth: string;
        gender: string;
        has_matured: boolean;
    }[];
};

export interface DataResponse<T> {
    data: T;
}

export interface FormResponseData {
    ok?: boolean;
    error?: string | string[];
    errors?: Record<string, string[]>;
    data?: { [key: string]: any };
    message?: string;
    result?: ApplicationActionButtonResponseResultApiInterface;
    status?: number;
}

export interface ApplicationActionButtonResponseApiInterface {
    message: string;
    result: ApplicationActionButtonResponseResultApiInterface;
}

export interface ApplicationRedirectData {
    url: string;
    new_window: boolean;
}

export interface ApplicationActionButtonResponseResultApiInterface {
    type: 'url' | 'export_pdf';
    data:
        | ApplicationRedirectData
        | ExportInterface<InternationalCertificateExportData>;
}

export class UserService {
    async register(
        data: MemberRegistrationApiInterface
    ): Promise<FormResponseData> {
        return await newFetcher(
            '/api/user/register',
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    /**
     * Register an additional member to the current user. User already has a member.
     */
    async registerAdditionalMember(
        data: MemberRegistrationApiInterface
    ): Promise<FormResponseData> {
        return await newFetcher(
            '/api/user/register_additional_member',
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    /**
     * Registration made by club administrator
     */
    async registerByClubAdmin(
        data: MemberRegistrationApiInterface
    ): Promise<FormResponseData> {
        return await newFetcher(
            '/api/user/register_by_club_admin',
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    async registerCheck(
        data: MemberRegistrationApiInterface
    ): Promise<FormResponseData> {
        return await fetcherFull(
            '/api/user/check/register',
            MethodEnum.POST,
            null,
            { data }
        );
    }
    async uploadPhoto(
        data: UploadPhotoApiInterface
    ): Promise<FormResponseData> {
        return await newFetcher(
            '/api/user/uploadPhoto',
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    async me(): Promise<UserMe | false> {
        const response: AxiosResponse = await fetcher('/api/user/me', 'GET');

        if (response.status === 200) {
            return await response.data;
        } else {
            return false;
        }
    }

    async applicationsCreationTypes(): Promise<string[]> {
        return (
            (await fetcher(`/api/user/me/applications/create`, MethodEnum.GET, {
                discipline: getDiscipline(),
            })) as AxiosResponse<{ data: string[] }>
        ).data.data;
    }

    async registerCheckResend(data: {
        [key: string]: string;
    }): Promise<FormResponseData> {
        const response: AxiosResponse = await fetcher(
            '/api/user/check/resend',
            'POST',
            null,
            { data }
        );

        if (response.status === 200) {
            return { ok: true };
        } else {
            return {
                error: response.data?.data?.code,
            };
        }
    }

    async update(
        data: Record<string, string>
    ): Promise<Record<string, string>> {
        return await newFetcher(
            '/api/user/update',
            MethodEnum.POST,
            undefined,
            data
        );
    }

    async create(data: CreateUserApiInterface): Promise<FormResponseData> {
        return await newFetcher(
            '/api/user/create',
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    async myOrganisationUnits(): Promise<
        AxiosResponse<UserOrganisationUnit[]>
    > {
        return await fetcherFull(
            '/api/user/me/organisation_units',
            MethodEnum.GET
        );
    }

    async myOrganisationUnitRoles(
        orgUnitId: number,
        query: QueryRequestInterface
    ): Promise<AxiosResponse<UserOrganisationUnitRole[]>> {
        return await newFetcher(
            `/api/user/me/organisation_units/${orgUnitId}/roles`,
            MethodEnum.GET,
            query
        );
    }

    async myGroups(): Promise<AxiosResponse> {
        return await fetcherFull('/api/user/me/groups', MethodEnum.GET);
    }

    async createFromPerson(
        data: CreateUserFromPersonApiInterface
    ): Promise<FormResponseData> {
        return await newFetcher(
            '/api/user/create_from_person',
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    async getList(query: NextApiRequestQuery): Promise<AxiosResponse> {
        return await fetcherFull('/api/user/list', MethodEnum.GET, query);
    }

    async requestPasswordReset(
        data: ForgottenPasswordRequestInterface
    ): Promise<AxiosResponse> {
        return await newFetcher(
            `/api/user/request_password_reset`,
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    async resetPassword(
        data: ForgottenPasswordConfirmRequestInterface
    ): Promise<AxiosResponse> {
        return await newFetcher(
            `/api/user/reset_password`,
            MethodEnum.POST,
            undefined,
            { data }
        );
    }

    async resendSetup(
        data: ResendPasswordRequestDataInterface
    ): Promise<AxiosResponse> {
        return await newFetcher(
            `/api/user/resend`,
            MethodEnum.POST,
            undefined,
            { data }
        );
    }
}
