import Cookies from 'js-cookie';
import {
    TokenPurpose,
    getJwtExpiration,
    getTokenName,
} from '../cookies.shared';

const REDIRECT_COUNTER_NAME = 'redirect_counter';

const MAX_AGE = 30; // 30 days

export const setCookie = (
    name: string,
    value: string,
    expires: number | Date = MAX_AGE
): void => {
    Cookies.set(name, value, {
        expires: expires,
        // Secure only works on https or on http://localhost.
        secure: true,
        path: '/',
        sameSite: 'lax',
    });
};
export function extractCookieValues(authCookie: string) {
    const regex = /\[([^\]]+)\]\[([^\]]+)\]=([^;]+)/; // Upravený regex pro zachycení požadovaných částí
    const match = authCookie.match(regex);

    if (match) {
        const cookieName = match[1]; // První klíč
        const innerKey = match[2]; // Vnitřní klíč
        const cookieValue = `${innerKey}=${match[3]}`; // Vnitřní klíč a hodnota

        return { cookieName, cookieValue };
    } else {
        throw new Error('Message format is incorrect');
    }
}
export const removeCookie = (name: string): void => {
    Cookies.remove(name);
};

export const getCookie = (name: string): string | null => {
    // eslint-disable-next-line security/detect-object-injection
    return Cookies.get() ? Cookies.get()[name] : null;
};

export function setTokenCookie(
    token: string,
    tokenPurpose: TokenPurpose
): void {
    const cookieName = getTokenName(tokenPurpose);
    const expirationDate = getJwtExpiration(token);

    // No exp property
    if (!expirationDate) {
        setCookie(cookieName, token);
        return;
    }
    // Save the cookie with proper expiry date
    setCookie(cookieName, token, expirationDate);
}

export function removeTokenCookie(tokenPurpose: TokenPurpose): void {
    const cookieName = getTokenName(tokenPurpose);
    Cookies.remove(cookieName);
}

export function removeTokenCookies(): void {
    removeTokenCookie(TokenPurpose.Access);
    removeTokenCookie(TokenPurpose.Refresh);
}

export function getTokenCookie(tokenPurpose: TokenPurpose): string | null {
    const cookieName = getTokenName(tokenPurpose);
    return Cookies.get(cookieName);
}

export function addToRedirectCookie(): void {
    const oldCount = Number(Cookies.get(REDIRECT_COUNTER_NAME));
    setCookie(
        REDIRECT_COUNTER_NAME,
        oldCount ? (oldCount + 1).toString() : '1'
    );
}

export function removeRedirectCookie(): void {
    Cookies.remove(REDIRECT_COUNTER_NAME);
}

export function getRedirectCookie(): string | null {
    return Cookies.get(REDIRECT_COUNTER_NAME);
}
